import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
function ProgressItem({name, value, style}) {
  const { t } = useTranslation();

  return (
    <>
      <div style={style} className="progress-item">
        <p>{t(name)}</p>
        <div className="progress-container">
          <div className="progress-bar">
            <div style={{ width: `${value}%` }} className="progress-value">{value}%</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgressItem;
