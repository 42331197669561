// @ts-nocheck
import PropTypes from "prop-types";
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from "react-select-async-paginate";
import { useTranslation, i18n } from "react-i18next";
import translations from '../../data/ingredients_translations.json'

import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import { ITEMS_PER_PAGE } from "common/constants";
import { components } from "react-select";
import { getResponse } from "common/utils";
import { FaInfoCircle } from 'react-icons/fa'; // Importing an info icon from react-icons

type SelectAsyncPaginateProps = {
  datasource?: Array<any>;
  query: string;
  mapper?: Function;
  onChange: Function;
  dataField: string;
  value: string;
  placeholder: string;
  isMulti?: boolean;
  multiOptions?: Array<any>;
  count?: number;
  isMyMeals?: boolean;
  isDisabled?: boolean;
  isEvent?: boolean
  is_demo?: boolean
};

const CustomInput = (inputProps: any) => {
  const count = (inputProps.selectProps?.value as any[] ?? []).length

  if (count <= 2) {
    //@ts-ignore
    return <components.Input {...inputProps}></components.Input>;
  }

  return (
    //@ts-ignore
    <span style={{ display: "flex", alignItems: "center", color: "black" }}>
      <span>{`${count} items selected`}</span>
      <components.Input {...inputProps}></components.Input>
    </span>
  );
};



const SelectAsyncPaginate = (props: SelectAsyncPaginateProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language
  const { selectedRestaurantId, hasRetaurants, isRestaurantLoaded } =
    useUserData();

    const CustomSingleValue = ({ children, ...props }) => {
      return (
        <components.SingleValue {...props}>
          {props.data?.source === 'CHATGPT' && (
              <FaInfoCircle title={t("The value of this ingredient has not been certified yet by an external organisation")} style={{ marginRight: 5, zIndex: 999, cursor: 'pointer' }} />
          )}
          {children}
        </components.SingleValue>
      );
    };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? "#F3F4FB"
              : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
    menu: (provided) => ({ ...provided, zIndex: 99999 }),
    multiValueLabel: (base) => ({ ...base }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: data?.source === 'CHATGPT' ? '#6353ea' : 'black',
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const loadOptions = async (
    searchQuery,
    loadedOptions,
    { page }
  ): Promise<any> => {
    if (props?.is_demo) {
      return {
        options: props.datasource?.map(i => {
          return {
            ...i,
            id: i.item_id
          }
        }),
        hasMore: false,
        additional: { page: 1 },
      };
    }

    if (!selectedRestaurantId && props.is_demo) {
      return { options: [], hasMore: false, additional: { page: 1 } };
    }
    let updatedSearchQuery = searchQuery
    let matchingElement = []
    if (currentLanguage == 'fr' && props.query === 'carbon-footprints/ingredients') {
      matchingElement = Object.keys(translations).filter(key => key?.toLowerCase().includes(updatedSearchQuery?.toLowerCase()))
    }
    const payload = {
      ...(props.query !== 'brands' && { restaurant_id: selectedRestaurantId }),
      limit: ITEMS_PER_PAGE,
      page,
      ...(props.dataField === "is_external" && { is_external: true }),
      ...(searchQuery && { search: updatedSearchQuery }),
    };

    if (props.query === 'carbon-footprints/ingredients') {
      delete payload.restaurant_id
    }

    let response
    if (!matchingElement.length) {
      response = await request.get(props.query, payload, true, false);
    } else {
      response = { cf_ingredients: matchingElement.map(item => ({ name: item, french: true })) }
    }

    if (props.query === 'carbon-footprints/ingredients' && response?.cf_ingredients?.length === 0) {
      response = await request.get("carbon-footprints/ingredients/get-co2-emission", { qry: searchQuery }, true, false);
      response = {
        cf_ingredients: [
          {
            id: null,
            name: response.co2_emission.qry,
            carbon_emission: response.co2_emission.co2_impact_per_kg,
            unit: "kg",
            french: false,
            format: 1,
            ingredient_id: {
              id: null,
              name: response.co2_emission.qry,
              unit: "kg",
            }
          }
        ]
      }
    }

    let optionsObj = {
      options: props.mapper
        ? props.mapper(props.query === 'carbon-footprints/ingredients' ? response['cf_ingredients'].map(i => ({ ...i, name: t(i?.name), ingredient_id: { id: i?.id, name: t(i?.name), unit: i?.unit } })) : response[props.query])
        : getResponse(response, props.query),
      ...(props.multiOptions && {
        options: [{ label: "Meals", options: response[props.query] }],
      }),
      ...(payload.page === 1 &&
        props.multiOptions && {
        options: [
          { label: "Meal Lists", options: props.multiOptions },
          { label: t("Meals"), options: response[props.query] },
        ],
      }),
      hasMore: response.total_pages > response.page,
      additional: { page: response.page + 1 },
    };
    if (props.isMyMeals) {
      return {
        ...optionsObj, options: optionsObj.options.map(i => {
          return {
            ...i,
            name: i.is_external ? i.name : i.name + " - " + t("Recipe")
          }
        })
      }
    }
    return optionsObj
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(props.dataField)(option);
    }
  };

  const additionalProps = props.isMulti ? {
    styles: {
      ...colourStyles,
      multiValue: (base) => ({
        ...base,
        display: props.count && props.count > 2 ? 'none' : 'flex',
      })
    },
    components: {
      Input: CustomInput,
      SingleValue: CustomSingleValue, // Use the custom single value component
    }
  } : {
    styles: { ...colourStyles },
    components: {
      SingleValue: CustomSingleValue, // Use the custom single value component
    }
  };

  return (
    <AsyncPaginate
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      value={props.value?.ingredient_id ? { ...props.value, id: props.value?.ingredient_id?.id, name: t(props.value?.ingredient_id?.name) } : { ...props.value }}
      key={props.value}
      //@ts-ignore
      loadOptions={loadOptions}
      getOptionValue={(option) => (option as any)?.id}
      getOptionLabel={(option) => (option as any)?.name}
      onChange={onChange}
      isSearchable={true}
      {...additionalProps}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder}
      additional={{ page: 1 }}
      debounceTimeout={1000}
      closeMenuOnSelect={props.isMulti ? false : true}
      hideSelectedOptions={false}
      isMulti={props.isMulti || false}
      {...(props.multiOptions && { reduceOptions: reduceGroupedOptions })}
    />
  );
};

SelectAsyncPaginate.propTypes = {
  dataField: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  query: PropTypes.string,
  mapper: PropTypes.func,
  key: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default SelectAsyncPaginate;
