import React, { useState, useContext } from "react";
import storage from "services/storage";

const getCurrentUser = () => {
  const user = JSON.parse(storage.getItem("user"));
  return {
    id: user?.id ?? null,
    username: user?.username ?? "",
    email: user?.email ?? "",
    currency: user?.currency ?? "",
  };
};

export const AuthContext = React.createContext({
  setUserData: () => {},
  id: "",
  username: "",
  email: "",
  selectedRestaurantId: "",
  setSelectedRestaurantId: () => {},
  restaurants: [],
  setRestaurants: () => {},
  isFilterShown: false,
  setFilterShown: () => {},
  setStocksLevelDown: () => {},
  selectedRestaurant: null,
  isRestaurantLoaded: false,
  hasRetaurants: false,
  stockEvolution: {},
  setStockEvolution: () => {},
  sampleRestaurantModal: false,
  setSampleRestaurantModal: () => {},
  currentUser: null,
  currency: "",
  setResetStock: () => {},
  resetStock: false
});

export const AuthContextProvider = (props) => {
  const setUserData = ({ id, username, email, currency }) => {
    setState((prevState) => ({
      ...prevState,
      ...(id && { id }),
      username,
      email,
      currency,
    }));
  };

  const setResetStock = (value) => {
    setState((prevState) => ({
      ...prevState,
      resetStock: value,
    }));
  };

  const setSelectedRestaurantId = (id) => {
    setState((prevState) => ({
      ...prevState,
      selectedRestaurantId: id,
      selectedRestaurant: prevState?.restaurants?.find((r) => r.id === id),
    }));
  };

  const setStockEvolution = (stocksData) => {
    setState((prevState) => ({
      ...prevState,
      stockEvolution: stocksData,
    }));
  };

  const setRestaurants = (restaurants, isRestaurantLoaded = true) => {
    setState((prevState) => ({
      ...prevState,
      restaurants,
      isRestaurantLoaded,
      hasRetaurants: Boolean(restaurants.length),
    }));
  };

  const setFilterShown = (isFilterShown) => {
    setState((prevState) => ({ ...prevState, isFilterShown }));
  };

  const setSampleRestaurantModal = (value) => {
    setState((prevState) => ({
      ...prevState,
      sampleRestaurantModal: value,
    }));
  };

  const initState = {
    id: "",
    username: "",
    email: "",
    setUserData,
    selectedRestaurantId: "",
    setSelectedRestaurantId,
    restaurants: [],
    setRestaurants,
    setStockEvolution,
    isFilterShown: false,
    setFilterShown,
    stockEvolution: {},
    selectedRestaurant: null,
    isRestaurantLoaded: false,
    hasRetaurants: false,
    isStocksLevelDown: false,
    sampleRestaurantModal: false,
    setSampleRestaurantModal,
    ...getCurrentUser(),
    resetStock: false,
    setResetStock
  };

  const [state, setState] = useState(initState);

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
};

export function useUserData() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useUserData must be used within AuthContextProvider");
  }
  return context;
}
